<template>
<div id="page" class="cost-pending-index">
  <el-form :model="form" class="row-center-start" ref="formRef">
    <el-form-item prop="txt_filter_number">
      <el-input v-model="form.txt_filter_number" placeholder="受试者筛选号">
        <template #prepend>
          <i class="iconfont icon-sousuo" />
        </template>
      </el-input>
    </el-form-item>
    <el-form-item prop="dateRange">
      <el-date-picker value-format="YYYY-MM-DD" v-model="form.dateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
    </el-form-item>
    <el-form-item prop="project" label="项目">
      <el-select v-model="form.t_project_id">
        <el-option v-for="(item, index) in projectOption" :key="index" :label="item.txt_project_name" :value="item.t_project_id"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="getList()">搜索</el-button>
      <el-button type="info" @click="reset()">重置</el-button>
    </el-form-item>
  </el-form>
  <el-table :data="table.data" v-loading="table.loading" header-cell-class-name="table-cell">
    <el-table-column prop="txt_project_name" label="所属项目" align="center" />
    <el-table-column prop="txt_examinee_spell" label="受试者姓名" align="center" />
    <el-table-column prop="txt_filter_number" label="受试者筛选号" align="center" />
    <el-table-column prop="txt_interview_name" label="访视阶段" align="center" />
    <el-table-column prop="dec_compensation_total" label="费用金额（元）" align="center" />
    <el-table-column prop="dt_verify" label="申请时间" align="center">
      <template #default="scope">{{scope.row.dt_verify||"- -"}}</template>
    </el-table-column>
    <el-table-column prop="txt_researcher_verify_status" label="审核状态" align="center">
      <template #default="scope">
        <template v-if="scope.row.txt_researcher_verify_status==1">已同意</template>
        <template v-else>已拒绝</template>
      </template>
    </el-table-column>
    <el-table-column label="操作" align="center" class-name="table-option">
      <template #default="scope">
        <div class="table-icon" @click="showDialog(scope.row)">详情</div>
      </template>
    </el-table-column>
  </el-table>
  <pagination ref="pageRef" :total="table.total" @sizeChange="(e) => {form.limit=e;getList()}" @pageChange="(e) => {form.page=e;getList()}" />
  <audit v-if="dialog.show" :dialogData="dialog" @close="(e)=>{dialog.show=false;if(e==1){getList()}}" />
</div>
</template>

<script>
// 访视费用申请 - 已处理申请
import { useStore } from "vuex";
import { ElMessage } from 'element-plus';
import { Pagination, Audit } from "components";
import { getProjectByUserId, getCostList } from "api/apis.js";
import { reactive, toRefs, onMounted, computed, ref } from 'vue';
// "txt_compensation_status": null, //费用申请状态 （null-待审核  -1 -已取消   1 -审核中   2 -已审核   3 -研究者驳回   4 -申办方驳回   5 -已发放）
export default {
  components: {
    Pagination,
    Audit
  },
  setup() {
    const pageRef = ref(null);
    const store = useStore();
    const state = reactive({
      form: {
        txt_filter_number: null,
        dateRange: [],
        t_project_id: "",
        page: 1,
        limit: 10
      },
      table: {
        total: 0,
        data: [],
        loading: false
      },
      projectOption: [],
      dialog: {
        show: false,
        application_id: 0
      },
      researcherInfo: computed(() => store.getters.researcherInfo),
    });

    onMounted(() => {
      getProject();
    })

    // 获取项目列表
    const getProject = () => {
      getProjectByUserId({ t_researcher_id: state.researcherInfo.t_researcher_id }).then(response => {
        if (response.code == 200) {
          state.projectOption = response.data;
          getList();
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      })
    }

    // 显示审核dialog
    const showDialog = item => {
      state.dialog.show = true;
      state.dialog.application_id = item.t_compensation_application_id;
      state.dialog.t_researcher_id = item.t_researcher_id;
      state.dialog.txt_researcher_verify_status = item.txt_researcher_verify_status;
      state.dialog.type = "detail";
    };

    // 获取列表
    const getList = () => {
      state.table.loading = true;
      state.table.data = [];
      let param = {
        t_researcher_id: state.researcherInfo.t_researcher_id,
        t_project_id: state.form.t_project_id,
        txt_filter_number: state.form.txt_filter_number,
        dt_from: (state.form.dateRange||[]).length == 0 ? null : state.form.dateRange[0],
        dt_end: (state.form.dateRange||[]).length == 0 ? null : state.form.dateRange[1],
        status: 1,
        pages: state.form.page,
        pageSize: state.form.limit
      };
      let keys = Object.keys(param);
      for (let i = 0; i < keys.length; i++) {
        if ((param[keys[i]] || "") == "") {
          delete param[keys[i]];
        }
      }

      getCostList(param).then(response => {
        if (response.code == 200) {
          if (response.data) {
            state.table.data = response.data.data;
            state.table.total = response.data.totalRow;
          } else {
            state.table.data = [];
            state.table.total = 0;
          }
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.loading = false;
      })
    };

    // 重置表单，重新搜索
    const reset = () => {
      state.form = {
        txt_filter_number: null,
        dateRange: [],
        t_project_id: "",
        page: 1,
        limit: 10
      };
      pageRef.value.pagination.pageSize = 10;
      pageRef.value.pagination.currentPage = 1;
      getList();
    }

    return {
      ...toRefs(state),
      getList,
      reset,
      showDialog,
      pageRef
    }
  }
}
</script>

<style lang="scss" scoped>
.cost-pending-index {
  .el-table {
    height: calc(100% - 100px);
  }
}
</style>
